import { Component, OnInit } from '@angular/core';
import { CarouselInfo } from '../../interface/ec-template.interface';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  data: CarouselInfo[] = [
    {
      title: 'Most popular',
      description: 'Our users love these',
      img: 'https://picsum.photos/2800/700?random=9',
      button: { display: false }
    },
    {
      title: 'Discounted',
      description: 'Best value for money',
      img: 'https://picsum.photos/2800/700?random=10',
      button: { display: false }
    },
    {
      title: 'Newest',
      description: 'Our newest added products',
      img: 'https://picsum.photos/2800/700?random=11',
      button: { display: false, content: 'button content', href: '#' }
    }
  ];

  constructor() {}

  ngOnInit() {}
}

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
// Modules
import { SharedModule } from './shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CategoryModule } from './category/category.module';
import { NotifierModule } from 'angular-notifier';
// Services
import { DataService } from './services/data.service';
// Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { CarouselComponent } from './front-page/carousel/carousel.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { EmptyComponent } from './empty/empty.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentComponent } from './payment/payment.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule} from  '@angular/material/select';

import { createCustomElement } from '@angular/elements';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SigninComponent } from './login/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminComponent } from './admin/admin.component';

import {MatTabsModule} from '@angular/material/tabs';


const firebaseConfig = {
  apiKey: "AIzaSyA6RAIRGRKk7kL5NQn-92cRHmMRWrKd4Uo",
  authDomain: "inf-demo-store.firebaseapp.com",
  databaseURL: "https://inf-demo-store-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "inf-demo-store",
  storageBucket: "inf-demo-store.appspot.com",
  messagingSenderId: "106270268525",
  appId: "1:106270268525:web:a0dc4798adb9c9564bf364"
}

import 'src/assets/elements/elements.js';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FrontPageComponent,
    CarouselComponent,
    ShoppingCartComponent,
    EmptyComponent,
    NotFoundComponent,
    PaymentComponent,
    SigninComponent,
    SignupComponent,
    ProfileComponent,
    PaymentComponent,
    AdminComponent

  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, HttpClientModule, FormsModule, ReactiveFormsModule, MatIconModule, MatInputModule,
    MatSelectModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    SharedModule,
    TooltipModule.forRoot(),
    CategoryModule,
    AngularFireModule.initializeApp(firebaseConfig, 'demo-store'),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
        NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right'
        },
        vertical: {
          position: 'top'
        }
      }
    })
  ],
  providers: [DataService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {

    // const bankListEl = createCustomElement(BanksListComponent, {
    //   injector: this.injector,
    // });
    // if (!customElements.get('irispay-add-iban')) { 
    //   customElements.define('irispay-add-iban', bankListEl);
    // }

    // const irisUi =  createCustomElement(IrispayUiComponent, {
    //   injector: this.injector,
    // });
    // if (!customElements.get('irispay-ui')) { 
    //   customElements.define('irispay-ui', irisUi);
    // }

    // const paymentEL = createCustomElement(PaymentComponent, {
    //   injector: this.injector,
    // });
    // if (!customElements.get('irispay-payment')) { 
    //   customElements.define('irispay-payment', paymentEL);
    // }

    // const loginEl = createCustomElement(LoginComponent, {
    //   injector: this.injector,
    // });
    // if (!customElements.get('irispay-login')) { 
    //   customElements.define('irispay-login', loginEl);
    // }

  }
}

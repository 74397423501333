import { Component, OnInit, Renderer2 } from '@angular/core';
import { DataService } from '../services/data.service';
import { OrderInfo } from '../interface/ec-template.interface';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
    data: OrderInfo;
    form: FormGroup;
    constructDescription = '';
    gateway = '0';
    userhash;

    node
    private unlistener1;
    private unlistener2;

    constructor(private dataService: DataService, private fb: FormBuilder, private router: Router, private renderer2: Renderer2,public sanitizer: DomSanitizer) {
        this.userhash = localStorage.getItem('user-iris-hash');
    }

    ngOnInit() {
        this.scrollToTop();
        this.data = this.dataService.getOrderInfo();
        console.log(this.data);
        this.data.items.forEach(item => {
            this.constructDescription = `Поръчка: {{item.product.id}}`;
        });


        // redirect to shopping cart page if no item in the cart
        if (this.data.items.length === 0) {
            this.router.navigate(['shopping-cart']);
        }

        this.form = this.fb.group({
            customerInfo: this.fb.group({
                name: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                phoneNumber: ['', Validators.required]
            }),
            paymentInfo: this.fb.group({
                holderName: ['', Validators.required],
                cardNumber: ['', [Validators.required, Validators.minLength(12)]],
                expiredDate: ['', Validators.required],
                cvc: ['', [Validators.required, Validators.minLength(3)]]
            }),
            deliveryInfo: this.fb.group({
                recipientName: ['', Validators.required],
                recipientNumber: ['', Validators.required],
                addressLine1: ['', Validators.required],
                addressLine2: [''],
                city: ['', Validators.required],
                state: ['', Validators.required],
                postcode: ['', Validators.required],
                country: ['', Validators.required]
            })
        });
    }

    onSubmit() {
        if (this.form.valid) {
            this.data.customerInfo = this.form.get('customerInfo').value;
            this.data.deliveryInfo = this.form.get('deliveryInfo').value;
            this.data.paymentInfo = this.form.get('paymentInfo').value;
            this.dataService.submitOrder(this.data);
        } else {
            this.validateAllFormFields(this.form);
            this.scrollToTop();
        }
    }

    isFieldValid(field: string) {
        return !this.form.get(field).valid && this.form.get(field).touched;
    }

    isRequiredValid(field: string) {
        return this.form.get(field).hasError('required') && this.form.get(field).touched;
    }

    isMinLengthValid(field: string) {
        return this.form.get(field).hasError('minlength') && this.form.get(field).touched;
    }

    displayFieldCss(field: string) {
        return {
            'has-error': this.isFieldValid(field),
            'has-feedback': this.isFieldValid(field)
        };
    }

    // mark all field as touched
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    // Set delivery info if check box is checked
    toggleDeliveryCB(isChecked: boolean) {
        const recipientName = this.form.get('deliveryInfo.recipientName');
        const recipientNumber = this.form.get('deliveryInfo.recipientNumber');
        if (isChecked) {
            recipientName.setValue(this.form.get('customerInfo.name').value);
            recipientNumber.setValue(this.form.get('customerInfo.phoneNumber').value);
        } else {
            recipientName.reset();
            recipientNumber.reset();
        }
    }

    // Control input's limit characters
    limitedInputLength(field: string, limit: number) {
        const formControl = this.form.get(field);
        if (formControl.value.length > limit) {
            formControl.setValue(formControl.value.slice(0, limit));
        }
    }

    checkExpiredDatePattern(inputEvent: any) {
        const formControl = this.form.get('paymentInfo.expiredDate');
        if (inputEvent.inputType === 'insertText') {
            if (formControl.value.length > 5) {
                // can't be more than 5 characters
                formControl.setValue(formControl.value.slice(0, 5));
            } else if (formControl.value.length === 3) {
                // add '/' after the second number
                const front = formControl.value.slice(0, 2);
                const back = formControl.value.slice(2, 4);
                formControl.setValue(`${front}/${back}`);
            }
        }
    }

    getTotalItems() {
        let total = 0;
        for (const i of this.data.items) {
            total += +i.quantity;
        }
        return total;
    }

    scrollToTop() {
        const scrollToTop = window.setInterval(() => {
            const pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 6);
    }

    ngModelChange() {
        console.log(this.gateway);

    }

    choose(event) {
        console.log(event)
        if (this.gateway == '1') {
            setTimeout(x => { this.buildSdkComponent() }, 100)
        }
    }

    finalize() {
        this.dataService.submitOrder(this.data);
    }

    paymentIframeSrc
    buildSdkComponent() {
        // let container = document.getElementById('iris-payment-container');
        // if (container && this.data) {
        //     this.node = document.createElement("irispay-ui");
        //     this.node.setAttribute("backend", 'https://developer.sandbox.irispay.bg/');
        //     this.node.setAttribute("description",this.data.items[0].product.id);
        //     this.node.setAttribute("merchant", "Мобидик ЕООД");
        //     //this.node.setAttribute("amount", this.data.totalPrice);
        //     this.node.setAttribute("amount", 0.01);
        //     this.node.setAttribute("currency", 'BGN');
        //     this.node.setAttribute("publichash", 'a34d278a-8b10-48f0-9466-ece9fa2bf606');
        //     if(this.userhash){
        //         this.node.setAttribute("userhash", this.userhash);
        //     }


        //     container.appendChild(this.node);

        //     this.unlistener1 = this.renderer2.listen(this.node, "onlaststep", data => {
        //         console.log(`I am detecting onlaststep`);
        //         console.log(data)
        //         console.log('!!!!')

        //     });
        //     this.unlistener2 = this.renderer2.listen(this.node, "error", err => {
        //         console.log(`I am detecting error`);
        //         console.log(err)
        //         console.log('!!!!')
        //         console.log(err)
        //     });

        // }

        let backend = 'https://tbi-backend.infn.dev/'
        let description = this.data.items[0].product.id
        let currency = this.data.items[0].product.currency
        if(!currency){
            currency = 'RON'
        }
        let merchant = 'Мобидик ЕООД'
        let amount = 0.01
        let publichash = '1318621d-1579-4b6b-a8ca-edf9ad3bb7c7'
      //  let publichash = 'е6db978a8-dd78-4196-99c5-b9dcbd16036f'
      //  let hookhash = '148641f2-8d71-4f89-87b4-d548ebd11b50'
        let toIban = 'BG56UBBS80021090583830'
      //  let urlWithParams = `https://sdk.paybyclick.irispay.bg/#/new?merchant=${merchant}&amount=${amount}&currency=${currency}&publichash=${publichash}&backend=${backend}&description=${description}&toiban=${toIban}&useurlparams=true`
     let urlWithParams = `https://sdkframe.infn.dev/#/new?merchant=${merchant}&amount=${amount}&currency=${currency}&publichash=${publichash}&backend=${backend}&description=${description}&toiban=${toIban}&useurlparams=true`
  
      if (this.userhash) {
            urlWithParams += `&userhash=${this.userhash}`
        }
        console.log(urlWithParams)
        this.paymentIframeSrc= this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);

    }

    ngOnDestroy() {
        if (this.unlistener1) {
            this.unlistener1();
        }
        if (this.unlistener2) {
            this.unlistener2();
        }


    }
}

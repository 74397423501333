import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  orders:any;
  constructor(private authenticationService:DataService) { }

  ngOnInit() {

  }

  getOrders() {
    let uid = '';
    
    this.authenticationService.userData.subscribe(data => {
      uid = data.uid;
    })
   

    this.authenticationService.getall().snapshotChanges().pipe(
      map(changes =>
        changes.map((c:any) =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      this.orders = data;
    });
    console.log(this.orders);
  }


  signOut() {
    this.authenticationService.SignOut();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ProductInfo,
  CategoryInfo,
  ShoppingCartItem,
  OrderInfo
} from '../interface/ec-template.interface';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from 'rxjs';
import firebase from "firebase/app"
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';



const SHOPPING_CART_KEY = 'shopping-cart-data';
const ORDER_INFO_KEY = 'order-info';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private headers = new HttpHeaders();
  productList$ = new BehaviorSubject<ProductInfo[]>([]);
  categoryList$ = new BehaviorSubject<CategoryInfo[]>([]);
  currentCategory$ = new BehaviorSubject<string>('all');
  currentProductListByCategory$ = new BehaviorSubject<ProductInfo[]>([]);

  shoppingCartData: ShoppingCartItem[] = [];

  userData: Observable<firebase.User>;

  constructor(
    private http: HttpClient,
    private notifierService: NotifierService,
    private router: Router,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth
  ) {
    this.initData();
    this.currentCategory$.subscribe(() => {
      if (this.categoryList$.value.length !== 0) {
        this.getProductListByCategory();
      }
    });

    this.userData = angularFireAuth.authState;
  }

  setHeaders(key: string, value: string) {
    this.headers.set(key, value);
  }

  private initData() {
    this.loadShoppingCart();
    forkJoin(this.getAllProductList(), this.getCategoryList()).subscribe((data: any) => {
      this.productList$.next(data[0]);
      console.log('products:', this.productList$.value);
      this.categoryList$.next(data[1]);
      console.log('categories:', this.categoryList$.value);
      this.setCategoryCount();
      this.getProductListByCategory();
    });
  }

  private setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private getLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  private removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  private getAllProductList() {
    return this.http.get('./assets/data/product-list.json', { headers: this.headers });
  }

  private getCategoryList() {
    return this.http.get('./assets/data/category-list.json', { headers: this.headers });
  }
  private setCategoryCount() {
    // reset
    for (const category of this.categoryList$.value) {
      category.count = 0;
      category.products = [];
    }
    for (const product of this.productList$.value) {
      for (const c of this.categoryList$.value) {
        if (product.category === c.name) {
          c.count++;
          c.products.push(product);
        }
      }
    }
  }

  setCurrentCategory(category: string) {
    this.currentCategory$.next(category);
  }

  getMenuList() {
    return this.http.get('./assets/data/menu-list.json', { headers: this.headers });
  }

  private getProductListByCategory() {
    if (this.currentCategory$.value === 'all') {
      this.currentProductListByCategory$.next(this.productList$.value);
    } else {
      this.currentProductListByCategory$.next(
        this.categoryList$.value.find(data => {
          return data.name === this.currentCategory$.value;
        }).products
      );
    }
  }

  getProductById(productId: string) {
    return this.productList$.value.find(function(item, index, array) {
      return item.id === productId;
    });
  }

  getRelatedProductsByCategory(productId: string, category: string, amount: number) {
    const relatedProducts: ProductInfo[] = [];
    for (const c of this.categoryList$.value) {
      if (c.name === category) {
        for (let i = 0; relatedProducts.length < amount; i++) {
          if (c.products[i] && c.products[i].id !== productId) {
            relatedProducts.push(c.products[i]);
          }
        }
      }
    }
    return relatedProducts;
  }

  private loadShoppingCart() {
    if (this.getLocalStorage(SHOPPING_CART_KEY)) {
      this.shoppingCartData = this.getLocalStorage(SHOPPING_CART_KEY);
    }
    console.log('SC Data from LocalStorage', this.shoppingCartData);
  }

  addShoppingCartItem(item: ShoppingCartItem) {

    if (
      this.shoppingCartData.find(data => {
        // return data.product.id === item.product.id && data.option.value === item.option.value;
        return data.product.id === item.product.id;
      })
    ) {
      for (const i of this.shoppingCartData) {
        // if (i.product.id === item.product.id && i.option.value === item.option.value) {
        if (i.product.id === item.product.id) {
          i.quantity = i.quantity + item.quantity;
        }
      }
    } else {
      this.shoppingCartData = [...this.shoppingCartData, item];
    }
    console.log('item added:', this.shoppingCartData);
    this.setLocalStorage(SHOPPING_CART_KEY, this.shoppingCartData);
    this.notifierService.notify(
      'default',
      `Add ${item.product.name} to cart`
    );
  }

  editShoppingCartItem(item: ShoppingCartItem) {
    this.shoppingCartData = this.shoppingCartData.map((data: ShoppingCartItem) => {
      if (data.product.id === item.product.id) {
        data = Object.assign({}, data, item);
      }
      return data;
    });
    console.log('item edited:', this.shoppingCartData);
    this.setLocalStorage(SHOPPING_CART_KEY, this.shoppingCartData);
  }

  deleteShoppingCartItem(item: ShoppingCartItem) {
    this.shoppingCartData = this.shoppingCartData.filter(
    //   data => !(data.product.id === item.product.id && data.option.value === item.option.value)
    data => !(data.product.id === item.product.id )
    );
    console.log('item removed:', this.shoppingCartData);
    this.setLocalStorage(SHOPPING_CART_KEY, this.shoppingCartData);
    this.notifierService.notify(
      'warning',
      `Remove ${item.product.name}`
    );
  }

  saveOrderInfo(data: OrderInfo) {
    this.setLocalStorage(ORDER_INFO_KEY, data);
  }

  getOrderInfo() {
    return this.getLocalStorage(ORDER_INFO_KEY);
  }

  submitOrder(order: OrderInfo) {
    console.log('Order Info:', order);
    this.notifierService.notify('default', 'Submit Success');
    // Delete shopping cart items and order from local storage then redirect to shopping Cart
    this.removeLocalStorage(SHOPPING_CART_KEY);
    this.removeLocalStorage(ORDER_INFO_KEY);
    this.shoppingCartData = [];
    setTimeout(() => {
      this.router.navigate(['shopping-cart']);
    }, 2000);
  }

  create_order(record) {
    return this.firestore.collection('orders').add(record);
  }

  read_orders(uid) {
    //return this.firestore.collection('/orders').snapshotChanges();
    return this.firestore.collection('/orders', ref => ref.where("userID", "==", uid));
  }

  getall() {
    return this.firestore.collection('/orders');
  }

  update_order(recordID,record){
    this.firestore.doc('/orders/' + recordID).update(record);
  }

  delete_order(record_id) {
    this.firestore.doc('/orders/' + record_id).delete();
  }

  /* Sign up */
  SignUp(email: string, password: string, first_name:string, last_name:string) {
    this.angularFireAuth
    .createUserWithEmailAndPassword(email, password)
    .then(res => {
      this.irisRegistration(email, password, first_name, last_name);
      localStorage.setItem('user-mail', res.user.email);
      localStorage.setItem('user-uid', res.user.uid);
      localStorage.setItem('userLogged', 'true');
      this.router.navigate(['profile']);
    console.log('You are Successfully signed up!', res);
    })
    .catch(error => {
    console.log('Something is wrong:', error.message);
    });
  }
   
  /* Sign in */
  SignIn(email: string, password: string) {
    this.angularFireAuth
    .signInWithEmailAndPassword(email, password)
    .then(res => {
      localStorage.setItem('user-mail', res.user.email);
      localStorage.setItem('user-uid', res.user.uid);
      this.getHashFromC(res.user.uid);
      localStorage.setItem('userLogged', 'true');
      
    console.log('You are Successfully logged in!');
    })
    .catch(err => {
    console.log('Something is wrong:',err.message);
    });
  }
   
  /* Sign out */
  SignOut() {
    this.angularFireAuth
    .signOut();
    localStorage.removeItem('user-email');
    localStorage.removeItem('user-uid');
    localStorage.removeItem('userLogged');
    localStorage.removeItem('user-iris-hash');
    this.router.navigate(['login']);
    window.location.reload();
  }

  irisRegistration(email: string, password: string, first_name:string, last_name:string) {
    this.http.post(environment.backend + 'api/7/signup/public', {
        publicHash: environment.publicHash
      }, this.httpOptions).subscribe((res:any) => {
        this.http.post(environment.backend + 'api/7/signup/credentials', {
          email:email,
          name:first_name,
          family:last_name,
          password:password,
          userHash:res.userHash
        }, this.httpOptions).subscribe((reg:any) => {
          let record = {
            userID:localStorage.getItem('user-uid'),
            userHash:res.userHash
          }
          this.firestore.collection('hashes').add(record);
          localStorage.setItem('user-iris-hash', res.userHash);
        })
      })
    
    
  }

  private get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-user-hash':'',
        Accept: 'application/json',
        'Accept-language': 'bg',
      })
    };
  }

  getHashFromC(uid){
    let h = this.firestore.collection('/hashes').snapshotChanges().pipe(
      map(changes =>
        changes.map((c:any) =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      let h =  data.filter(item => item.userID === uid);
      localStorage.setItem('user-iris-hash', h[0].userHash);
      this.router.navigate(['profile']);
      window.location.reload();
    });;
  }

 
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class SigninComponent implements OnInit {

  constructor(private authenticationService:DataService) { }

  email: string;
  password: string;
  remail: string;
  rpassword: string;
  first_name: string;
  last_name: string;

  ngOnInit() {
  }

  signUp() {
    this.authenticationService.SignUp(this.remail, this.rpassword, this.first_name, this.last_name);
    
    this.remail = '';
    this.rpassword = '';
    this.first_name = '';
    this.last_name = '';
  }

  signIn() {
    this.authenticationService.SignIn(this.email, this.password);
    this.email = '';
    this.password = '';
  }
     
  signOut() {
    this.authenticationService.SignOut();
  }
}
